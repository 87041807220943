select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.rgb-main-menu-wrapper {
	background-color: #1b1c1d;
}

.rgb-main-menu.ui.menu {
	border-radius: 0;
	margin: auto;
	max-width: 735px;
}

.ui.menu .item>i.icon {
	margin: 0;
}

.rgb-main-menu.ui.menu .item {
	padding: 10px 15px;
}

.rgb-main-menu.ui.menu img:hover {
	cursor: pointer;
}

.ui.primary.button, 
.ui.primary.buttons .button {
	background-color: #00ABC7;
}

.ui.primary.button:hover, 
.ui.primary.buttons .button:hover {
	background-color: #33B6CC;
}

.ui.primary.button, .ui.primary.buttons .button {
	background-color: #00ABC7;
}

.ui.text.container {
	white-space: pre-wrap;
}

.ui.form input:not([type]), 
.ui.form input[type=date], 
.ui.form input[type=datetime-local], 
.ui.form input[type=email], 
.ui.form input[type=file], 
.ui.form input[type=number], 
.ui.form input[type=password], 
.ui.form input[type=search], 
.ui.form input[type=tel], 
.ui.form input[type=text], 
.ui.form input[type=time], 
.ui.form input[type=url],
textarea {
	font-size: 16px !important;
}

.rgbgalleries.root {
	margin-bottom: 30px;
}


